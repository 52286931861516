import { createEvent, createStore, createEffect, forward } from 'effector'
import { getUtmLabels } from 'api/gaApi'

// events
export const loadUtmLabels = createEvent()

// effects
export const fxLoadUtmLabels = createEffect().use(loadUtmLabelsHandler)

// stores
export const $utmLabels = createStore([])

//
// business logic
//
function loadUtmLabelsHandler(){
  return getUtmLabels().then(r => r.utmLabels)
}

$utmLabels.on(fxLoadUtmLabels.done, (_, {result}) => result)

forward({ from: loadUtmLabels, to: fxLoadUtmLabels })

