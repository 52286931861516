import { createEvent, createStore, createEffect, forward } from 'effector'
import { createGate } from 'effector-react'
import { getUserEvents } from 'api/gaApi'
import { eventNames } from 'features/analytics'
import { historyPush } from 'features/routing'
import { $project } from 'features/common'

// events
export const userIdChanged = createEvent()

//effects
const fxLoadUserEvents = createEffect().use(loadUserEventsHandler)

// gates
export const RouterGate = createGate()

// stores
export const $userEvents = createStore([])
export const $userEventsIsFetching = createStore(false)
export const $userId = RouterGate.state.map(calcUserId)
export const $userIdReady = $userId.map(userId => userId !== undefined)

//
// business logic
//
$userEvents.on(fxLoadUserEvents.done, (_, { result }) =>
  result.events.map((event, index) => ({ ...event, id: index + 1, name: eventNames[event.pagePath] }))
)

$userEventsIsFetching.on(fxLoadUserEvents, () => true).on(fxLoadUserEvents.finally, () => false)

function loadUserEventsHandler(userId) {
  return getUserEvents(userId)
}

function calcUserId(state) {
  if (state.match) return state.match.params.id || ''
  else return undefined
}

userIdChanged.watch(e => {
  const project = $project.getState()
  historyPush(`/${project}/user-events/${e.target.value}`)
})

forward({ from: $userId, to: fxLoadUserEvents })
