import axios from 'axios'
import { getConfig } from '../config'
import { $project } from 'features/common'

const { backUrl } = getConfig()

const instance = axios.create({
  baseURL: `${backUrl || ''}/api/`,
  timeout: 180000,    // 3 минуты
})

instance.interceptors.request.use(config => {
  config.headers.project = $project.getState()
  return config
})

instance.interceptors.response.use(
  function(response) {
    return response.data
  },
  function(error) {
    return Promise.reject(error)
  }
)

export function GET(url, query) {
  return instance.get(url, { params: query })
}

export function POST(url, data, config) {
  return instance.post(url, data, config)
}

export function PUT(url, data) {
  return instance.put(url, data)
}

export function DEL(url) {
  return instance.delete(url)
}
