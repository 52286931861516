import { createGate } from "effector-react"

export const RoutingGate = createGate()

export const $history = RoutingGate.state.map(store => store.history)
export const $location = RoutingGate.state.map(store => store.location)

export function historyPush(path) {
  $history.getState().push(path)
}

