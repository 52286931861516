export * from './funnels'
export * from './events'
export * from './ab'
export * from './utm'

const pagesEventNames = {
  '/event/adminApp/editButtonClick': 'Нажал редактировать блок',
  '/event/adminApp/open': 'Админское приложение открыто',
  '/event/adminApp/blockSettingsSave': 'Нажал "Применить" в настройках блока',
  '/event/promoMobile/pageOpen': 'Открыт мобильный лендинг',
  '/event/promoDesktop/pageOpen': 'Открыт десктоп лендинг',
  '/event/adminApp/saveAndExitClick': 'Нажал "Назад" при редактировании страницы (раньше было Сохранить и выйти)',
  '/event/adminApp/justSaveClick': 'Нажал "Сохранить изменения" при редактировании страницы',
  '/event/adminApp/createPageClick': 'Нажал "Создать страницу"',
  '/event/adminApp/pageTitleSave': 'Сохранил страницу',
  '/event/auth/groupCreated': 'Создалась группа в базе',
  '/event/adminApp/testPeriodActivated': 'Юзер активировал тестовый период',
  '/event/adminApp/selectLeadTemplate': 'Выбрал шаблон',
  '/event/adminApp/pageUrlCopy': 'Скопировал адрес страницы в буфер обмена',
  '/event/adminApp/tarifPageOpen': 'Открыл страницу тарифы',
  '/event/adminApp/tutorCompleted': 'Завершил тутор',
  '/event/adminApp/spycatBotSubscribeStart': 'В диалоге "разреши сообщения от чатбота" нажал на разрешить',
  '/event/promoDesktop/connectAppClick': 'Нажал на "подключить" приложение',
  '/event/adminApp/spycatBotSubscribeSuccess': 'Нажал "Разрешить" в вкшном попапе разрешения сообщений',
  '/event/adminApp/groupLinkClick': 'Кликнул на аватарку группы',
  '/event/promoDesktop/applicationAdded': 'Приложение добавлено в группу',
  '/event/adminApp/receiveFirstLead': 'Получил первого лида',
  '/event/adminApp/receiveFiveLeads': 'Получил 5 лидов',
  '/event/adminApp/receiveTenGroupJoins': 'Получил 10 вступлений в группу',
  '/event/adminApp/receiveTenPageClicks': 'Получил 10 кликов по кнопке / ссылке',
  '/event/adminApp/receiveTenConversions': 'Получил 10 конверсий',
  '/event/adminApp/receiveTenPageHits': 'Получил 10 посетителей на страницу',
  '/event/adminApp/PayClick': 'Нажал на "оплатить" на странице тарифы',
  '/event/adminApp/endTarifNotificationComplete': 'Не шлется',
  '/event/adminApp/paid': 'Заплатил',
  '/event/mainGroupLandingOpened/pageOpened': 'Открыл любой лендинг группы spycat с мобилки или десктопа',
  '/event/mainGroupLandingDesktop/pageOpened': 'Открыл лендинг группы spycat с десктопа',
  '/event/mainGroupLandingMobile/pageOpened': 'Открыл лендинг группы spycat с мобилки',
  '/event/mainGroupLanding/connectAppClick': 'Кликнул на "подключить прилу" (и десктоп и мобилка)',
  '/event/mainGroupLandingDesktop/connectAppClick': 'Кликнул на "подключить прилу" с десктопа',
  '/event/mainGroupLandingMobile/connectAppClick': 'Кликнул на "подключить прилу" с мобилки',
  '/event/mainGroupLanding/applicationAdded': 'Подключил прилу (и десктоп и мобилка)',
  '/event/mainGroupLandingDesktop/applicationAdded': 'Подключил прилу с десктопа',
  '/event/mainGroupLandingMobile/applicationAdded': 'Подключил прилу с мобилки',

  '/event/addedGroups/open': '[Страница "продолжить настройку"] открыта',
  '/event/addedGroups/goToSettingsClicked': '[Страница "продолжить настройку"] клик по кнопке "продолжить настройку"',

  '/event/landings/mainGroupLandingOpened': 'Открыт лендинг группы spycat',

  '/event/adminApp/buyBeforeEditModalShowed': "Модалка покупки при редактировании. Модалка открыта",
  '/event/adminApp/buyBeforeEditModalClosed': "Модалка покупки при редактировании. Модалка закрыта",
  '/event/adminApp/buyBeforeEditModalVideoStarted': "Модалка покупки при редактировании. Видео стартовало",
  '/event/adminApp/buyBeforeEditModalVideo3Sec': "Модалка покупки при редактировании. Проиграно 3 сек видео",
  '/event/adminApp/buyBeforeEditModalVideo5Sec': "Модалка покупки при редактировании. Проиграно 5 сек видео",
  '/event/adminApp/buyBeforeEditModalVideo10Sec': "Модалка покупки при редактировании. Проиграно 10 сек видео",
  '/event/adminApp/buyBeforeEditModalVideo15Sec': "Модалка покупки при редактировании. Проиграно 15 сек видео",
  '/event/adminApp/buyBeforeEditModalVideoEnded': "Модалка покупки при редактировании. Видео завершено",
  '/event/adminApp/buyBeforeEditModalPayClick': "Модалка покупки при редактировании. Кликнул кнопку 'Купить'",

  '/event/adminApp/previewAfterSaveShowed': "Превью после сохранения. Диалог открыт",
  '/event/adminApp/previewAfterSaveYesClicked': 'Превью после сохранения. Кликнул "Перейти в предпросмотр"',
  '/event/adminApp/previewAfterSaveNoClicked': 'Превью после сохранения. Кликнул "Нет, не хочу"',

  '/event/adminApp/welcomeScreensWithSubscribeStepCompleted': 'Завершил степ в онбординге',
}

const widgetsEventNames = {
  '/promoMobile.html': 'Открыт мобильный лендинг',
  '/event/promoMobile/open': 'Открыт мобильный лендинг',
  '/widgets/index': 'Открыт список виджетов',
  '/promoDesktop.html': 'Открыт десктоп лендинг',
  '/event/promoDesktop/open': 'Открыт десктоп лендинг',
  '/event/promoMobile/button1Click': 'Кликнул по первой кнопке в мобильном лендинге',
  '/app.html': 'Открыто страница админского приложения',
  '/widgets/catalog/sales': 'Открыт раздел "для продаж" в каталоге',
  '/payment/tarifs': 'Открыта страница с тарифами',
  '/event/widgets/createSelectType': 'Нажал на "Выбрать" в каталоге',
  '/widgets/catalog/navigation': 'Открыт раздел "для навигации" в каталоге',
  '/event/promoDesktop/button1Click': 'Кликнул по первой кнопке в десктопном лендинге',
  '/event/widgets/createButtonClick': 'Кликнул по кнопке "Создать виджет"',
  '/event/auth/groupCreated': 'Создалась группа в базе',
  '/event/widgets/createSelectName': 'Увидел инпут выбора имени виджета',
  '/event/specTarifForNew/clicked': 'Кликнул по "Ок понятно" в попапе про стартовый тариф (190/390)',
  '/event/welcomescreen/specTarifForNew': 'Показан попап про стартовый тариф',
  '/event/fullVersionNotification/offered': 'Показано окно "Купи полный тариф"',
  '/event/widgets/editButtonClick': 'Нажал на "Редактировать виджет"',
  '/event/publish/publishButtonClick': 'Нажал на "Публиковать виджет"',
  '/widgets/new/discounts': 'Выбрал виджет "Акции и скидки" в каталоге',
  '/event/pay/tarifListOpen': 'хз',
  '/event/widgets/editSelectName': 'Увидел окно выбора имени при редактировании виджета',
  '/event/widgets/createSave': 'Сохранил виджет (при создании)',
  '/event/widgets/editSave': 'Сохранил виджет (при редактировании)',
  '/event/publishwidget/success': 'Опубликовал виджет',
  '/event/promoDesktop/button2Click': 'Кликнул по второй кнопке в десктопном лендинге',
  '/widgets/new/personalOffer': 'Выбрал виджет "Персональное предложение" в каталоге',
  '/event/publish/publish1stwidget': 'Опубликовал первый виджет',
  '/event/tarif/tarifEnd': 'Taриф закончился',
  '/widgets/new/importantMessage': 'Выбрал виджет "Важное сообщение" в каталоге',
  '/widgets/new/menu': 'Выбрал виджет "Меню сообщества" в каталоге',
  '/event/bot/notificationSent': 'Бот отправил уведомление',
  '/widgets/new/coverSales': 'Выбрал виджет "Акции в обложках" в каталоге',
  '/event/pay/buyClick': 'Нажал "Оплатить" на странице тарифов',
  '/widgets/new/feedback': 'Выбрал виджет "Отзывы" в каталоге',
  '/help/index': 'Зашел на страницу помощь',
  '/widgets/new/recommended': 'Выбрал виджет "Рекомендованные товары" в каталоге',
  '/payment/history': 'Открыл страницу "история платежей"',
  '/event/pay/success': 'Заплатил',
  '/widgets/new/sales': 'Выбрал виджет "Акционные товары" в каталоге',
  '/event/widgets/getMoneyPosted': 'Запостили виджет - попрошайку',
  '/widgets/new/pointsSale': 'Выбрал виджет "Точки продаж" в каталоге',
  '/widgets/new/client': 'Выбрал виджет "Наши клиенты" в каталоге',
  '/event/buytarif/4': 'хз',
  '/widgets/new/subscribe': 'Выбрал виджет "Подписка на рассылки" в каталоге',
  '/event/pay/regularSuccess': 'Совершен регулярный платеж',
  '/event/buytarif/1': 'хз',
  '/event/publishwidget/error': 'Ошибка при публикации виджета',
  '/event/pay/buyPopupShow': 'хз',
  '/widgets/new/events': 'Выбрал виджет "Мероприятия" в каталоге',
  '/event/buytarif/2': 'хз',
  '/membership/main': 'хз',
  '/event/referal/register': 'хз',
  '/event/referal/payment': 'хз',

  '/event/widgets/previewClicked': 'Включил режим предпросмотра',
  '/event/widgets/showTargetingForm':  'Включил настройку аудитории',
  '/event/widgets/hideTargetingForm':  'Выключил настройку аудитории',

  '/event/menu/catalogClicked':  'Клик на каталог виджетов в меню',
  '/event/menu/tarifsClicked':  'Клик на тарифы в меню',
  '/event/menu/helpClicked':  'Клик на "Помощь" в меню',
  '/event/demoWidget/buyClick':  'Перешел на страницу оплаты с виджета',

  '/event/backClick/widgets_catalog':  'Кликнул назад в Каталоге виджетов',
  '/event/backClick/widgets_editForm':  'Кликнул назад на странице редактирования виджета',
  '/event/backClick/widgets_newForm':  'Кликнул назад на странице создания виджета',
  '/event/backClick/payment_tarifs':  'Кликнул назад на странице тарифы',

  '/event/pay/tarifSelected':  'Выбрал тариф',
  '/event/pay/periodSelected':  'Выбрал период',

  '/event/adminApp/getPhoneAndTakeDemoDaysOpened': '[Дай телефон] модалка показана',
  '/event/adminApp/getPhoneAndTakeDemoDaysApplyClicked': '[Дай телефон] - кликнул на "Применить"',
  '/event/adminApp/getPhoneAndTakeDemoDaysCancelClicked': '[Дай телефон] - кликнул на "Отмена"',
  '/event/adminApp/getPhoneAndTakeDemoDaysPhoneSaved': '[Дай телефон] - телефон отправлен, тестовый период активирован',

  '/event/adminApp/widgetsExamples/mouseEnter': '[Примеры виджетов] навел мышку',
  '/event/adminApp/widgetsExamples/click': '[Примеры виджетов] кликнул на скрин',
  '/event/adminApp/widgetsExamples/moreClick': '[Примеры виджетов] кликнул на "Еще примеры"',

  '/event/widget/widgetFormModalApplyClicked': 'Кликнул "Применить" в модалке редактирования виджета',
  '/event/widgets/editModalShowed': 'Отобразилась модалка редактирования виджета',
  '/event/publishwidget/showInGroupModalShowed': 'Показана модалка "Посмотри виджет в группе"',
  '/event/publishwidget/showInGroupModalToButtonGroupClicked': 'В модалке "Посмотри виджет в группе" кликнул "Перейти в группу"',
  '/event/publishwidget/showInGroupModalCanceled': 'В модалке "Посмотри виджет в группе" кликнул "Закрыть"',
}

export const eventNames = {
  ...widgetsEventNames,
  ...pagesEventNames,
}
