import { createEvent, createStore, createEffect } from 'effector'

const defaultUnitValue = {
  uaTable: [],
  leadsTable: [],
  firstCustomersTable: [],
  c1Table: [],
  c2Table: [],
  firstTransactionsTable: [],
  firstTransactionAvPriceTable: [],
  allTransactionsTable: [],
  totalMoneyTable: [],
  avPriceTable: [],
  arpuTable: [],
  periods: [],
  cohorts: [],
}

// events
export const updateDataClick = createEvent()
export const updateRawUnitData = createEvent()
export const writeNewUnitData = createEvent()
export const fetchUnitDataFx = createEffect()
export const saveUnitDataToLocalStorage = createEvent()

// stores
export const $isUnitDataFetching = fetchUnitDataFx.pending
export const $rawUnitTables = createStore(defaultUnitValue)
export const $periods = $rawUnitTables.map(raw => raw.periods || [])
export const $cohorts = $rawUnitTables.map(raw => raw.cohorts || [])
export const $leads = $rawUnitTables.map(raw => raw.leadsTable || [])
export const $firstCustomersTable = $rawUnitTables.map(raw => raw.firstCustomersTable || [])
export const $c1Table = $rawUnitTables.map(getC1)
export const $c2Table = $rawUnitTables.map(getC2)
export const $allTransactionsTable = $rawUnitTables.map(raw => raw.allTransactionsTable || [])
export const $totalMoneyTable = $rawUnitTables.map(raw => raw.totalMoneyTable || [])
export const $arpuTable = $rawUnitTables.map(getArpu)
export const $avPriceTable = $rawUnitTables.map(getAvgPrice)

function getC1(raw) {
  return (raw.c1Table || []).map(arr => arr.map(i => Math.round(i * 100 * 100) / 100))
}

function getC2(raw) {
  return (raw.c2Table || []).map(arr => arr.map(i => Math.round(i * 100 * 100) / 100))
}

function getAvgPrice(raw) {
  return (raw.avPriceTable || []).map(arr => arr.map(i => Math.round(i)))
}

function getArpu(raw) {
  return (raw.arpuTable || []).map(arr => arr.map(i => Math.round(i * 10) / 10))
}
