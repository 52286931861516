import { createEvent, createStore, createEffect, forward } from 'effector'
import { getEvents } from 'api/gaApi'
import { eventNames } from '../../index'

export const loadEvents = createEvent()

// effects
const fxLoadEvents = createEffect().use(loadEventsHandler)

// stores
export const $events = createStore([])
export const $isFetching = fxLoadEvents.pending

// business logic
function loadEventsHandler() {
  return getEvents().then(r => r.events)
}

export function getEventTitle(event) {
  // full event name
  if (eventNames[event]) return eventNames[event]

  // with label
  const match = event.match(/(\/event\/.*?\/.*?)\/(.*?)$/)
  if (match) {
    const name = eventNames[match[1]]
    if (name) {
      return `${name} (${match[2]})`
    }
  }

  return event
}


$events.on(fxLoadEvents.done, (_, { result }) =>
  result.map(event => ({ event: event, title: getEventTitle(event) }))
)

forward({ from: loadEvents, to: fxLoadEvents })
