import { createEvent, createStore, createEffect, sample, guard } from 'effector'
import moment from 'moment'
import { notification } from 'antd'
import { getUsersInfo } from 'api/gaApi'

// events
const usersSelected = createEvent()
const closeModal = createEvent()
const copyToBufferClicked = createEvent()

// effects
const getUsersDataFx = createEffect()
const copyUsersTableToBufferFx = createEffect()

// stores
const $userIds = createStore([])
const $usersList = createStore([])
const $isModalShowed = $userIds.map(ids => ids.length > 0)
const $isLoading = getUsersDataFx.pending

// init effects
const sleep = timeout => new Promise(resolve => setTimeout(resolve, timeout))
getUsersDataFx.use(async userIds => {
  const formatDate = dateStr => (dateStr ? moment(dateStr).format('YYYY-MM-DD') : '-')

  const resp = await getUsersInfo(userIds)
  return resp.usersInfo.map(user => ({
    ...user,
    key: user.userVkId,
    installDate: formatDate(user.installDate),
    lastPaymentDate: formatDate(user.lastPaymentDate),
  }))
})

copyUsersTableToBufferFx.use(usersList => {
  const rows = usersList.map(row =>
    [
      row.userVkId,
      row.lastGroupVkId,
      row.installDate,
      row.lastPaymentDate,
      row.lastPaymentTarif,
      row.lastPaymentSum,
      row.groupsCount,
    ].join('\t')
  )
  const str =
    ['Юзер', 'Группа', 'Установка прилы', 'Последний платеж', 'Тариф', 'Сумма', 'Количество групп'].join('\t') +
    '\n' +
    rows.join('\n')
  navigator.clipboard.writeText(str)
  notification.success({ message: 'Информация о юзерах скопирована в буфер' })
})

// init stores
$userIds.on(usersSelected, (_, userIds) => userIds).reset(closeModal)

$usersList.on(getUsersDataFx.doneData, (_, users) => users)

// routing
guard({ source: $userIds, target: getUsersDataFx, filter: userIds => userIds.length > 0 })
sample({ source: $usersList, clock: copyToBufferClicked, target: copyUsersTableToBufferFx })

// export
export { $isModalShowed, $isLoading, $usersList, usersSelected, closeModal, copyToBufferClicked }
