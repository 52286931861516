import { createEvent, forward } from 'effector'
import { loadAbOptions, loadEvents, loadUtmLabels } from 'features/analytics'
import { $project } from 'features/common'

// events
export const appMounted = createEvent()

//
// business logic
//
forward({ from: $project, to: [loadEvents, loadAbOptions, loadUtmLabels] })
