import React  from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { Spin, Table, Tooltip, Input } from 'antd'
import { useStore } from 'effector-react'
import { DefaultTemplate } from '../defaultTemplate'
import {
  $userEvents,
  $userEventsIsFetching,
  RouterGate,
  userIdChanged,
  $userId,
  $userIdReady,
} from './model'

const columns = [
  {
    title: 'Имя',
    dataIndex: 'name',
    key: 'name',
    render: (name, item) => <Tooltip title={item.pagePath}>{name}</Tooltip>,
  },
  {
    title: 'Время',
    dataIndex: '_',
    key: 'conversion',
    render: (_, item) => moment.utc(item.ts).format('DD.MM.YYYY HH:mm'),
  },
]

const InputContainer = styled.div`
  margin: 16px 0;
`

export function UserEventsPage(props) {
  const userEvents = useStore($userEvents)
  const userEventsIsFetching = useStore($userEventsIsFetching)
  const userId = useStore($userId)
  const userIdReady = useStore($userIdReady)

  return (
    <DefaultTemplate>
      <RouterGate {...props} />
      <h1>История действий юзера</h1>

      {userIdReady && (
        <InputContainer>
          <Input defaultValue={userId} onPressEnter={userIdChanged} placeholder={'Введите id юзера'} />
        </InputContainer>
      )}

      <Spin spinning={userEventsIsFetching}>
        <Table dataSource={userEvents} columns={columns} rowKey="id" pagination={false} />
      </Spin>
    </DefaultTemplate>
  )
}
