import React from 'react'
import styled from 'styled-components'
import { combine } from 'effector'
import { useStore } from 'effector-react'
import { Modal, Button, Table } from 'antd'
import { $isLoading, $isModalShowed, $usersList, closeModal, copyToBufferClicked } from './usersListModel'

function Footer() {
  return (
    <>
      <Button onClick={closeModal}>Отмена</Button>
      <Button onClick={copyToBufferClicked} type="primary">
        Скопировать в буфер
      </Button>
    </>
  )
}

const columns = [
  {
    title: 'Юзер',
    dataIndex: 'userVkId',
    key: 'userVkId',
    render: id => (
      <a href={`https://vk.com/id${id}`} target="_blank">
        {id}
      </a>
    ),
  },
  {
    title: 'Группа',
    dataIndex: 'lastGroupVkId',
    key: 'lastGroupVkId',
    render: id => (
      <a href={`https://vk.com/club${id}`} target="_blank">
        {id}
      </a>
    ),
  },
  {
    title: 'Установка прилы',
    dataIndex: 'installDate',
    key: 'installDate',
  },
  {
    title: 'Последний платеж',
    dataIndex: 'lastPaymentDate',
    key: 'lastPaymentDate',
  },
  {
    title: 'Тариф',
    dataIndex: 'lastPaymentTarif',
    key: 'lastPaymentTarif',
  },
  {
    title: 'Сумма',
    dataIndex: 'lastPaymentSum',
    key: 'lastPaymentSum',
  },
  {
    title: 'Количество групп',
    dataIndex: 'groupsCount',
    key: 'groupsCount',
  },
]

const Root = styled.div`
  width: 850px;
  height: 630px;
`

const $store = combine({ isLoading: $isLoading, isModalShowed: $isModalShowed, usersList: $usersList })

export function UsersListModal() {
  const { isLoading, isModalShowed, usersList } = useStore($store)

  if (!isModalShowed) return null

  return (
    <Modal title="Пользователи" visible footer={<Footer />} width={900} onCancel={closeModal}>
      <Root>
        <Table
          dataSource={usersList}
          columns={columns}
          loading={isLoading}
          scroll={{ y: 500 }}
          pagination={{ pageSize: 100, showSizeChanger: false, showTotal: total => `Всего: ${total}` }}
        />
      </Root>
    </Modal>
  )
}
