import { DefaultLayout } from 'ui/layout'
import { Menu } from 'antd'
import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { $project } from 'features/common'
import { useStore } from 'effector-react'

const links = [
  { title: 'Воронки', path: 'funnels' },
  { title: 'События', path: 'events' },
  { title: 'Действия юзера', path: 'user-events' },
  { title: 'Юнит', path: 'unit' },
  { title: 'Клиенты', path: 'client-expire' },
  // { title: 'Информация', path: 'info' },
  { title: 'Data Lens', path: 'data-lens' },
]

export const DefaultTemplate = props => {
  const project = useStore($project)
  return (
    <DefaultLayout
      {...props}
      topMenu={
        <Menu theme="dark" mode="horizontal" selectedKeys={[project]} style={{ lineHeight: '64px' }}>
          <Menu.Item key="pages">
            <Link to="/pages/funnels">Spycat.Pages</Link>
          </Menu.Item>
          {/*<Menu.Item key="pages2">*/}
          {/*  <Link to="/pages2/funnels">Spycat.Pages2</Link>*/}
          {/*</Menu.Item>*/}
          <Menu.Item key="widgets">
            <Link to="/widgets/funnels">Spycat.Widgets</Link>
          </Menu.Item>
        </Menu>
      }
      leftMenu={
        <Menu mode="inline" style={{ height: '100%' }}>
          {links.map((link, index) => (
            <Menu.Item key={index}>
              <NavLink to={link.path} activeStyle={{ fontWeight: 'bold' }}>
                {link.title}
              </NavLink>
            </Menu.Item>
          ))}
        </Menu>
      }
    />
  )
}
