import { forward, sample } from 'effector'
import { $project } from 'features/common'
import { getUnitParams } from 'api/unitApi'
import {
  updateDataClick,
  $rawUnitTables,
  updateRawUnitData,
  fetchUnitDataFx,
  writeNewUnitData,
  saveUnitDataToLocalStorage,
} from './model'
import moment from 'moment'

// ==
// init
// ==
fetchUnitDataFx.use(project =>
  getUnitParams({
    periods: getPeriodsForUnit(project),
  }).then(i => i.unitData)
)
$rawUnitTables.on(writeNewUnitData, (_, data) => data)

function getPeriodsForUnit(project) {
  const isWidgets = project === 'widgets'
  const now = moment()
  const restDate = isWidgets ? moment('1900-09-01') : null
  const startDate = isWidgets ? moment('2019-05-01') : moment('2019-07-01')

  // const restDate = null
  // const startDate = moment('2020-01-01')

  const res = []
  if (restDate) res.push({ from: restDate.toDate().toISOString(), to: startDate.toDate().toISOString() })

  let curDate = startDate.clone()
  while (curDate <= now) {
    res.push({
      from: curDate.toDate().toISOString(),
      to: curDate
        .clone()
        .add(1, 'month')
        .toISOString(),
    })
    curDate.add(1, 'month')
  }
  return res
}

getPeriodsForUnit()

// ==
// flow
// ==
updateRawUnitData.watch(async project => {
  const data = JSON.parse(localStorage.getItem(`rawUnitData.${project}`))
  if (data) writeNewUnitData(data)
  else fetchUnitDataFx(project)
})

sample({ source: $project, clock: updateDataClick, target: fetchUnitDataFx })
forward({ from: $project, to: updateRawUnitData })
forward({ from: fetchUnitDataFx.doneData, to: writeNewUnitData })

sample({
  source: $project,
  clock: fetchUnitDataFx.doneData,
  target: saveUnitDataToLocalStorage,
  fn: (project, unitData) => ({ project, unitData }),
})

saveUnitDataToLocalStorage.watch(({ project, unitData }) => {
  localStorage.setItem(`rawUnitData.${project}`, JSON.stringify(unitData))
})
