import { createStore } from 'effector'
import { createGate } from 'effector-react'

export const ProjectParamGate = createGate()

export const $project = createStore('')

$project.on(ProjectParamGate.state, (_, { match }) => {
  if (!match) return null
  return match.params.project || null
})
