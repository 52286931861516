import React from 'react'
import moment from 'moment'
import { Table, Spin, Tooltip, Divider, Button } from 'antd'
import { SyncOutlined, EditOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { toggleCollapse, getEventTitle } from 'features/analytics'
import { usersSelected } from './usersListModal'

const Container = styled.div`
  > * {
    margin-bottom: 8px !important;
  }
`

const ButtonsContainer = styled.div`
  text-align: right;
  > * {
    margin-left: 16px;
  }
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`

const HeaderCont = styled.div`
  display: flex;
  justify-content: space-between;
`

function formatDate(date) {
  return moment(date).format('DD.MM.YYYY')
}

function CohortName({ cohort, abOptions, index, funnelType }) {
  const { from, to, abOptionId, utmKey } = cohort
  const showOnlyDate = funnelType === 'multi'
  const abOption = abOptions.find(i => i.id === abOptionId)

  return (
    <div>
      <b>Когорта {index + 1} </b> <Divider type="vertical" />
      <b>Дата: </b>
      {formatDate(from)} - {formatDate(to)}
      <Divider type="vertical" />
      {!showOnlyDate && (
        <span>
          <b>АБ:</b>{' '}
          {abOption ? (
            <span>
              {abOption.experimentName} / {abOption.optionName}
            </span>
          ) : (
            'нет'
          )}
        </span>
      )}
      <Divider type="vertical" />
      {!showOnlyDate && (
        <span>
          <b>UTM:</b> {utmKey ? <span>{utmKey}</span> : 'нет'}
        </span>
      )}
    </div>
  )
}

function ConversionValue({ values = [], index, prefix = null, suffix = null }) {
  const value = values[index]
  const isMax = Math.max(...values) === value
  const isMin = Math.min(...values) === value
  let color = '#000'
  if (isMax) color = '#237804'
  if (isMin) color = '#610b00'
  const sign = value < values[0] ? '' : '+'
  const delta = Math.round(((value - values[0]) / values[0]) * 100 * 10) / 10
  const deltaStr = delta ? `${sign}${delta}%` : '-'
  return value === undefined ? (
    <div>-</div>
  ) : (
    <Tooltip title={deltaStr}>
      <span style={{ color }}>
        {prefix}
        {value.toString().replace('.', ',')}
        {suffix}
      </span>
    </Tooltip>
  )
}

function CountWithDownload({ count, ids = [] }) {
  return (
    <a
      onClick={() => {
        usersSelected(ids)
      }}
    >
      {count}
    </a>
  )
}

function getSimpleFunnelColumns({ cohorts = [] }) {
  return [
    {
      title: 'Событие',
      dataIndex: 'step',
      key: 'step.path',
      render: step => <Tooltip title={step.path}>{getEventTitle(step.path)}</Tooltip>,
    },
    {
      title: '-',
      dataIndex: 'step',
      key: 'step.path2',
      render: step => <div style={{ width: '50px', overflowX: 'hidden', whiteSpace: 'nowrap' }}>{step.path}</div>,
    },
    {
      title: 'Конверсия',
      dataIndex: 'conversion',
      key: 'conversion',
      children: cohorts.map((_, index) => ({
        title: `Ког ${index + 1}`,
        dataIndex: `coh${index + 1}`,
        key: `conversion.coh${index + 1}`,
        render: (_, item, colIndex) => {
          if (colIndex === 0) return ''
          const conversions = item.allowedUsersCount.map((_, index) => {
            if (item.inputUsersCount[index] === 0) return 0
            const percent = (item.allowedUsersCount[index] / item.inputUsersCount[index]) * 100
            return Math.round(percent * 10) / 10
          })
          return <ConversionValue index={index} values={conversions} suffix="%" />
        },
      })),
    },
    {
      title: 'Юзеров на входе',
      dataIndex: 'inputUsersCount',
      key: 'inputUsersCount',
      children: cohorts.map((_, index) => ({
        title: `Ког ${index + 1}`,
        dataIndex: `coh${index + 1}`,
        key: `inputUsersCount.coh${index + 1}`,
        render: (_, item) => item.inputUsersCount[index],
      })),
    },
    {
      title: 'Юзеров прошли дальше',
      dataIndex: 'allowedUsersCount',
      key: 'allowedUsersCount',
      children: cohorts.map((_, index) => ({
        title: `Ког ${index + 1}`,
        dataIndex: `coh${index + 1}`,
        key: `allowedUsersCount.coh${index + 1}`,
        render: (_, item) => <CountWithDownload count={item.allowedUsersCount[index]} ids={item.allowedUsers[index]} />,
      })),
    },
    {
      title: 'Юзеров потеряно',
      dataIndex: 'disabledUsersCount',
      key: 'disabledUsersCount',
      children: cohorts.map((_, index) => ({
        title: `Ког ${index + 1}`,
        dataIndex: `coh${index + 1}`,
        key: `disabledUsersCount.coh${index + 1}`,
        render: (_, item) => (
          <CountWithDownload count={item.disabledUsersCount[index]} ids={item.disabledUsers[index]} />
        ),
      })),
    },
  ]
}

function getMultiFunnelColumns({ conversionsByDay = [] }) {
  if (conversionsByDay.length === 0) return []

  const conversionColumns = conversionsByDay[0].conversions.map((item, index) => ({
    title: getEventTitle(item.step.name),
    dataIndex: 'conversions',
    render: item => item[index].allowedUsersCount,
  }))

  return [
    {
      title: 'Дата',
      dataIndex: 'date',
      key: 'date',
      width: 130,
    },
    ...conversionColumns,
  ]
}

function Header({ id, name, meta, applyParams, startEdit }) {
  return (
    <HeaderCont>
      <h3>{name}</h3>

      <ButtonsContainer>
        <Button type="link" onClick={() => toggleCollapse({ id })}>
          {meta.collapsed ? 'Развернуть' : 'Свернуть'}
        </Button>
        <a onClick={() => applyParams({ id })}>
          <SyncOutlined style={{ fontSize: '20px' }} />
        </a>
        <a onClick={() => startEdit({ id })}>
          <EditOutlined type="edit" style={{ fontSize: '20px' }} />
        </a>
      </ButtonsContainer>
    </HeaderCont>
  )
}

export function FunnelView({ id, funnelData = {}, meta = {}, startEdit, abOptions, name, applyParams, funnelType }) {
  const { conversions = [], cohorts = [] } = funnelData

  const { loadingStepsCompleted, loadingStepsTotal } = meta
  const loadingPercent = Math.round((loadingStepsCompleted / loadingStepsTotal) * 100)

  const isSimple = funnelType === 'simple'

  const totalConversions = []
  if (conversions.length >= 2) {
    const firstStep = conversions[0]
    const lastStep = conversions[conversions.length - 1]
    firstStep.allowedUsersCount.forEach((_, index) =>
      totalConversions.push(
        Math.round((lastStep.allowedUsersCount[index] / firstStep.allowedUsersCount[index]) * 100 * 10) / 10
      )
    )
  }

  return (
    <Container>
      <Header id={id} name={name} meta={meta} applyParams={applyParams} startEdit={startEdit} />
      {cohorts.map((cohort, index) => {
        return <CohortName key={index} cohort={cohort} abOptions={abOptions} index={index} funnelType={funnelType} />
      })}

      {meta.isFetching && !isSimple && (
        <div>
          Загрузка... {loadingPercent}% ({loadingStepsCompleted} / {loadingStepsTotal})
        </div>
      )}

      <Spin spinning={meta.isFetching}>
        {!meta.collapsed && (
          <div style={{width: '100%', overflowX: 'scroll'}}>
            <Table
              dataSource={isSimple ? funnelData.conversions : funnelData.conversionsByDay}
              columns={
                isSimple
                  ? getSimpleFunnelColumns({ cohorts: funnelData.cohorts })
                  : getMultiFunnelColumns({ conversionsByDay: funnelData.conversionsByDay })
              }
              rowKey={item => (isSimple ? item.step.path : item.date)}
              pagination={false}
              bordered
            />
          </div>
        )}
        {isSimple && (
          <Footer>
            <div>
              <b>Итоговая конверсия:</b>
              {totalConversions.map((conversion, index) => (
                <div key={index}>
                  <ConversionValue
                    values={totalConversions}
                    index={index}
                    prefix={`Когорта ${index + 1}: `}
                    suffix="%"
                  />
                </div>
              ))}
            </div>
          </Footer>
        )}
      </Spin>
    </Container>
  )
}
