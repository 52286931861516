import React from 'react'
import {Card} from "antd"
import { DefaultTemplate } from '../defaultTemplate'


export function InfoPage() {
  return (
    <DefaultTemplate>
      <h1>Разная инфа важная для аналитики</h1>

      <Card title={"12.12.2019"}>
          Были пофиксены события <b>receiveTenPageHits, receiveTenPageClicks, receiveTenGroupJoins, receiveTenConversions</b>.<br/>
          До этого в них передавался неверный clientId. Использовать <b>их в воронках можно только начиная с 13.12.2019</b>
      </Card>

    </DefaultTemplate>
  )
}
