import React from 'react'
import { Layout, Breadcrumb } from 'antd'
const { Header, Content, Footer, Sider } = Layout

export function DefaultLayout({ children, topMenu, leftMenu }) {
  return (
    <Layout>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <div style={{ maxWidth: '2000px', width: '100%' }}>
          <Header className="header">{topMenu}</Header>

          <Content style={{ padding: '0 50px' }}>
            <Breadcrumb style={{ margin: '16px 0' }} />
            <Layout style={{ padding: '24px 0', background: '#fff' }}>
              <Sider width={200} style={{ background: '#fff' }}>
                {leftMenu}
              </Sider>
              <Content style={{ padding: '0 24px', minHeight: 280 }}>{children}</Content>
            </Layout>
          </Content>
          <Footer style={{ textAlign: 'center' }} />
        </div>
      </div>
    </Layout>
  )
}
