import React from 'react'
import { useStore } from 'effector-react'
import { $expireClients, loadExpireClientsFx } from './model'
import { DefaultTemplate } from '../defaultTemplate'
import { Spin, Table, Tabs } from 'antd'
import { $project } from 'features/common'
import moment from 'moment'

const tarifNames = {
  widgets: {
    1: 'Просто виджет',
    2: 'Для бизнеса',
    3: 'Для крутого SMM',
    4: 'Просто виджет (скидка)',
    5: 'Для малого бизнеса',
  },
  pages:{
    2: "Про"
  }
}

const columns = [
  {
    title: 'Группа',
    dataIndex: 'groupVkId',
    key: 'groupVkId',
    render: id => (
      <>
        <a href={`https://vk.com/club${id}`} target="_blank">
          Группа
        </a>
        &nbsp;|&nbsp;
        <a href={`https://vk.com/app5686299_-120826490#godMode=${id}`} target="_blank">
          gm
        </a>
      </>
    ),
  },
  {
    title: 'Пользователь',
    dataIndex: 'userVkId',
    key: 'userVkId',
    render: id =>
      id ? (
        <>
          <a href={`https://vk.com/id${id}`} target="_blank">
            Юзер
          </a>
          &nbsp;|&nbsp;
          <a href={`https://vk.com/gim120826490?sel=${id}`} target="_blank">
            msg
          </a>
        </>
      ) : (
        '-'
      ),
  },
  {
    title: 'Текущий тариф',
    dataIndex: 'currentTarif',
    key: 'currentTarif',
    render: tarif => tarifNames[$project.getState()][tarif] || tarif,
  },
  {
    title: 'Заканчивается',
    dataIndex: 'paidTill',
    key: 'paidTill',
    render: value => (value !== null ? moment(value).format('DD.MM.YYYY') : '-'),
  },
  {
    title: 'Осталось дней',
    dataIndex: 'daysToOff',
    key: 'daysToOff',
    render: value => (value !== null ? value : '-'),
  },
  {
    title: 'Дата последней оплаты',
    dataIndex: 'lastPaymentDate',
    key: 'lastPaymentDate',
    render: value => (value !== null ? moment(value).format('DD.MM.YYYY') : '-'),
  },
  {
    title: 'Период',
    dataIndex: 'lastPaymentPeriod',
    key: 'lastPaymentPeriod',
    render: value => (value !== null ? value : '-'),
  },
  {
    title: 'Сумма',
    dataIndex: 'lastPaymentSum',
    key: 'lastPaymentSum',
    render: value => (value !== null ? value : '-'),
  },
]

export function ClientExpirePage() {
  const isLoading = useStore(loadExpireClientsFx.pending)
  const clients = useStore($expireClients)
  React.useEffect(() => {
    loadExpireClientsFx()
  }, [])

  return (
    <DefaultTemplate>
      <Spin spinning={isLoading}>
        <Tabs aultActiveKey="1">
          <Tabs.TabPane tab="Скоро кончатся" key="1">
            <Table dataSource={clients.soonExpired} columns={columns} rowKey="groupVkId" pagination={{pageSize: 100}} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Уже кончились" key="2">
            <Table dataSource={clients.alreadyExpired} columns={columns} rowKey="groupVkId" pagination={{pageSize: 100}}/>
          </Tabs.TabPane>
        </Tabs>
      </Spin>
    </DefaultTemplate>
  )
}
