import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { DatePicker, Select, AutoComplete } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

const CohortItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  > * {
    margin-right: 16px !important;
  }
`

const DeleteContainer = styled.span`
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`

export const CohortsEdit = ({
  cohorts = [],
  updateCohort,
  deleteCohort,
  addCohort,
  abOptions,
  utmLabels,
  funnelType,
}) => {
  const [filterStr, setFilterStr] = React.useState('')
  const showOnlyDate = funnelType === 'multi'

  function updateDates(index, from, to) {
    updateCohort({
      index,
      newValues: { from: from && from.format('YYYY-MM-DD'), to: to && to.format('YYYY-MM-DD') },
    })
  }
  function updateAbOption(index, abOptionId) {
    updateCohort({
      index,
      newValues: { abOptionId, utmKey: null },
    })
  }

  function updateUtm(index, utmKey) {
    updateCohort({
      index,
      newValues: { utmKey, abOptionId: null },
    })
  }

  return (
    <div>
      {cohorts.map((cohort, index) => (
        <CohortItem key={index}>
          <DatePicker.RangePicker
            value={[cohort.from, cohort.to].filter(Boolean).map(i => moment(i))}
            onChange={dates => {
              if (dates) updateDates(index, dates[0], dates[1])
              else updateDates(index, undefined, undefined)
            }}
          />
          {!showOnlyDate && (
            <Select
              value={cohort.abOptionId || null}
              onChange={abOption => updateAbOption(index, abOption)}
              style={{ width: 260 }}
            >
              <Select.Option value={null}>Выберите АБ</Select.Option>
              {abOptions.map(option => (
                <Select.Option value={option.id} key={option.id}>
                  {option.experimentName} / {option.optionName}
                </Select.Option>
              ))}
            </Select>
          )}

          {!showOnlyDate && (
            <AutoComplete
              options={utmLabels.filter(label => label.key.includes(filterStr)).map(label => ({ value: label.key }))}
              style={{ width: 400 }}
              onSelect={val => updateUtm(index, val)}
              onSearch={val => {
                setFilterStr(val)
                updateUtm(index, val)
              }}
              placeholder="Выберите UTM"
              allowClear
            />
          )}

          {!showOnlyDate && (
            <DeleteContainer>
              <CloseOutlined
                style={{ fontSize: '20px', opacity: 0.5 }}
                onClick={() => {
                  deleteCohort({ index })
                }}
              />
            </DeleteContainer>
          )}
        </CohortItem>
      ))}
      {!showOnlyDate && <a onClick={addCohort}>Добавить когорту</a>}
    </div>
  )
}
