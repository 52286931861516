import { GET, POST } from './apiHelper'

const prefix = ''

const sleep = (timeout = 1000) => new Promise(res => setTimeout(res, timeout))

export async function getFunnel({ cohorts, pages }) {
  const steps = pages.map(i => ({ name: i, path: i }))
  return POST(`${prefix}/funnel`, { cohorts, steps })
}

/**
 * Воронки, разбитые по дням
 */
export async function getMultiFunnel({ cohorts, pages }) {
  const steps = pages.map(i => ({ name: i, path: i }))
  return POST(`${prefix}/funnel-multi`, { cohorts, steps })
}

export function getEvents() {
  return GET(`${prefix}/events`)
}

export function getUserEvents(userId) {
  return GET(`${prefix}/user-events/${userId}`)
}

export function getABOptions() {
  return GET(`${prefix}/ab-options`)
}

export function getUtmLabels() {
  return GET(`${prefix}/utm-labels`)
}

export function getUsersInfo(userIds) {
  return POST(`${prefix}/users-info`, { userIds })
}

export function getExpireClients() {
  return GET(`${prefix}/expire-clients`)
}

export function ping() {
  return GET(`${prefix}/ping`)
}
