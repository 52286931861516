import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { EventsPage } from 'pages/events'
import { SimpleFunnelsPage, MultiFunnelsPage } from 'pages/funnels'
import { InfoPage } from 'pages/info'
import { UnitPage } from 'pages/unit'
import { UserEventsPage } from 'pages/userEvents'
import { RoutingGate } from 'features/routing'
import { ProjectParamGate } from 'features/common'
import { ClientExpirePage } from '../pages/clientExpire'

export function Routing() {
  return (
    <Router>
      <Route component={RoutingGate} />
      <Route path="/:project" component={ProjectParamGate} />
      <Switch>
        <Route path="/:project/funnels" component={SimpleFunnelsPage} exact/>
        <Route path="/:project/events" component={EventsPage} exact />
        <Route path="/:project/user-events" component={UserEventsPage} exact />
        <Route path="/:project/user-events/:id" component={UserEventsPage} exact />
        <Route path="/:project/unit" component={UnitPage} exact />
        <Route path="/:project/client-expire" component={ClientExpirePage} exact />
        <Route path="/:project/info" component={InfoPage} exact />
        <Route path="/:project/data-lens" component={MultiFunnelsPage} exact />
        <Route path="/">
          <Redirect to="/pages/funnels" />
        </Route>
      </Switch>
    </Router>
  )
}
