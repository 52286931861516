import React, { useState } from 'react'
import { useStore } from 'effector-react'
import { Input, notification, Spin, Table } from 'antd'
import { $events, $isFetching } from '../model'

const columns = [
  {
    title: 'Событие',
    dataIndex: 'event',
    key: 'event',
    render: event => {
      return (
        <a
          onClick={e => {
            navigator.clipboard.writeText(event)
            notification.success({ message: 'Событие скопированно в буфер' })
            e.preventDefault()
          }}
        >
          {event}
        </a>
      )
    },
  },
  {
    title: 'Подсказка',
    dataIndex: 'title',
    key: 'title',
  },
]

export function EventsList({ limit = 20 }) {
  const [filterStr, setFilterStr] = useState('')

  const events = useStore($events)
  const filteredEvents = events
    .filter(
      event =>
        filterStr === '' ||
        event.event.toLowerCase().includes(filterStr) ||
        event.title.toLowerCase().includes(filterStr)
    )
    .slice(0, limit)

  const isFetching = useStore($isFetching)
  return (
    <>
      <Spin spinning={isFetching}>
        <Input
          value={filterStr}
          onChange={e => setFilterStr(e.target.value.toLowerCase())}
          placeholder="Фильтруем события"
        />
        <Table dataSource={filteredEvents} columns={columns} rowKey="event" pagination={false} />
      </Spin>
    </>
  )
}
