import moment from 'moment'
import { createEvent, createEffect, combine, forward, merge, createStore } from 'effector'
import uuid from 'uuid'
import Bottleneck from 'bottleneck'
import { $project } from 'features/common'
import { createStoreWithLocalStorageSync } from './lib'
import { getFunnel, getMultiFunnel } from 'api/gaApi'

// events
export const addFunnelClick = createEvent()
const addFunnel = addFunnelClick.map(() => ({ id: uuid(), funnelType: $currentFunnelsType.getState() }))
export const applyParams = createEvent()
const restoreFunnels = createEvent()
export const startEdit = createEvent()
const cancelEdit = createEvent()
export const getFunnelFailed = createEvent()
export const setCohorts = createEvent()
export const setPages = createEvent()
export const setName = createEvent()
export const deleteFunnel = createEvent()
export const toggleCollapse = createEvent()
export const setCurrentFunnelType = createEvent()
export const loadMultiFunnelStarted = createEvent() // начали загрузку мультидневной воронки
export const multiFunnelLoadingStepCompleted = createEvent() // загрузили один из дней мультидневной воронки

// effects
const fxLoadFunnelData = createEffect().use(loadFunnelData)

// stores
const $funnelIds = createStoreWithLocalStorageSync([], '$funnelIds') // []
const $funnelCohorts = createStoreWithLocalStorageSync({}, '$funnelCohorts') // {[id]: [{ from, to, abOptions }] }
const $funnelData = createStoreWithLocalStorageSync({}, '$funnelData') // {[id]: []}
const $funnelMode = createStoreWithLocalStorageSync({}, '$funnelMode') // {[id]: 'edit' | 'view' }
const $funnelPages = createStoreWithLocalStorageSync({}, '$funnelPages') // {[id]: [] }
const $funnelMeta = createStoreWithLocalStorageSync({}, '$funnelMeta', {
  serializer: value =>
    JSON.stringify(
      Object.fromEntries(Object.entries(value).map(([key, value]) => [key, { ...value, isFetching: false }]))
    ),
  deserializer: value => {
    const meta = JSON.parse(value)
    const entries = Object.entries(meta).map(([key, value]) => [key, { ...value, isFetching: false }])
    return Object.fromEntries(entries)
  },
}) // {[id]: {meta: {isFetching, collapsed}}}
const $funnelToProject = createStoreWithLocalStorageSync([], '$funnelToProject') // {[id]: 'pages' | 'widgets' }
const $funnelName = createStoreWithLocalStorageSync({}, '$funnelName') // {[id]: {name}}
const $funnelType = createStoreWithLocalStorageSync({}, '$funnelType') // {[id]: {type}}
export const $currentFunnelsType = createStore('simple')
const $funnelsOfAllProjects = combine(
  $funnelIds,
  $funnelCohorts,
  $funnelPages,
  $funnelMode,
  $funnelData,
  $funnelMeta,
  $funnelToProject,
  $funnelName,
  $funnelType,
  $currentFunnelsType,
  funnelCombine
)
export const $funnels = combine($funnelsOfAllProjects, $project, filterCurrentProjectFunnels)

// business logic
function funnelCombine(
  funnelIds,
  funnelCohorts,
  funnelPages,
  funnelMode,
  funnelData,
  funnelMeta,
  funnelToProject,
  funnelName,
  funnelType,
  currentFunnelsType
) {
  return funnelIds
    .map(id => ({
      id,
      cohorts: funnelCohorts[id],
      pages: funnelPages[id],
      mode: funnelMode[id],
      data: funnelData[id] || [],
      meta: funnelMeta[id],
      project: funnelToProject[id],
      name: funnelName[id] || '',
      type: funnelType[id] || 'simple',
    }))
    .filter(f => f.type === currentFunnelsType)
}

function filterCurrentProjectFunnels(allFunnels, project) {
  return allFunnels.filter(funnel => funnel.project === project)
}

function loadSimplerFunnelData({ id }) {
  const cohorts = $funnelCohorts.getState()[id]
  const pages = $funnelPages.getState()[id]
  return getFunnel({ cohorts, pages }).then(r => r.funnels)
}

async function loadMultiFunnelData({ id }) {
  const cohorts = $funnelCohorts.getState()[id]
  const pages = $funnelPages.getState()[id]
  const steps = pages.map(i => ({ name: i, path: i }))

  const dates = []
  let cur = moment(cohorts[0].from)
  while (cur <= moment(cohorts[0].to)) {
    dates.push(cur.format('YYYY-MM-DD'))
    cur.add(1, 'day')
  }

  loadMultiFunnelStarted({ id, stepsCount: dates.length })

  const limiter = new Bottleneck({
    maxConcurrent: 2,
  })

  let lastError = null
  const limitedGetFunnel = limiter.wrap((...args) => {
    if (lastError) throw lastError
    return getFunnel(...args)
  })

  const funnelsByDays = await Promise.all(
    dates.map(date =>
      limitedGetFunnel({ pages, cohorts: [{ from: date, to: date }] }).then(d => {
        multiFunnelLoadingStepCompleted({ id })
        return d.funnels
      }).catch(e => {
        lastError = e
        throw e
      })
    )
  )

  const conversionsByDay = funnelsByDays.map(funnel => {
    const { cohorts, conversions } = funnel
    const clearedConversions = conversions.map(i => {
      delete i.allowedUsers
      delete i.disabledUsers
      return i
    })
    return { date: cohorts[0].from, conversions: clearedConversions }
  })

  return { steps, cohorts, conversionsByDay }
}

function loadFunnelData({ id }) {
  const type = $funnelType.getState()[id]

  if (type === 'multi') {
    return loadMultiFunnelData({ id })
  } else {
    return loadSimplerFunnelData({ id })
  }
}

function deleteId(dataMap, { id }) {
  const res = { ...dataMap }
  delete res[id]
  return res
}

const defaultCohorts = [
  {
    from: moment()
      .subtract(4, 'days')
      .format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  },
]

const defaultPagesEvents = [
  '/event/promoDesktop/pageOpen',
  '/event/auth/groupCreated',
  '/event/adminApp/tarifPageOpen',
  '/event/adminApp/paid',
]

const defaultWidgetsEvents = [
  '/event/auth/groupCreated',
  '/event/widgets/createSave',
  '/event/publishwidget/success',
  '/event/pay/success',
]

$funnelIds
  .on(addFunnel, (ids, { id }) => [...ids, id])
  .on(deleteFunnel, (ids, { id }) => ids.filter(funnelId => funnelId !== id))

$funnelCohorts
  .on(addFunnel, (dataMap, { id }) => ({ ...dataMap, [id]: defaultCohorts }))
  .on(setCohorts, (dataMap, { id, value }) => ({ ...dataMap, [id]: value }))
  .on(deleteFunnel, deleteId)

$funnelMode
  .on(merge([addFunnel, startEdit]), (dataMap, { id }) => ({ ...dataMap, [id]: 'edit' }))
  .on(applyParams, (dataMap, { id }) => ({ ...dataMap, [id]: 'view' }))
  .on(deleteFunnel, deleteId)

$funnelPages
  .on(addFunnel, (dataMap, { id }) => ({
    ...dataMap,
    [id]: $project.getState() === 'pages' ? defaultPagesEvents : defaultWidgetsEvents,
  }))
  .on(setPages, (dataMap, { id, value }) => ({ ...dataMap, [id]: value }))
  .on(deleteFunnel, deleteId)

$funnelData
  .on(fxLoadFunnelData.done, (funnelData, { params, result }) => {
    return { ...funnelData, [params.id]: result }
  })
  .on(deleteFunnel, deleteId)

$funnelMeta
  .on(fxLoadFunnelData, (funnelMeta, params) => updateFieldInIdMap(funnelMeta, params.id, 'isFetching', true))
  .on(fxLoadFunnelData.finally, (funnelMeta, { params }) =>
    updateFieldInIdMap(funnelMeta, params.id, 'isFetching', false)
  )
  .on(toggleCollapse, (funnelMeta, { id }) =>
    updateFieldInIdMap(funnelMeta, id, 'collapsed', !funnelMeta[id].collapsed)
  )
  .on(loadMultiFunnelStarted, (funnelMeta, { id, stepsCount }) => {
    return updateFieldsInIdMap(funnelMeta, id, { loadingStepsTotal: stepsCount, loadingStepsCompleted: 0 })
  })
  .on(multiFunnelLoadingStepCompleted, (funnelMeta, { id }) => {
    const currentStepsCompleted = funnelMeta[id].loadingStepsCompleted || 0
    return updateFieldInIdMap(funnelMeta, id, 'loadingStepsCompleted', currentStepsCompleted + 1)
  })
  .on(deleteFunnel, deleteId)

$funnelToProject
  .on(addFunnel, (dataMap, { id }) => ({ ...dataMap, [id]: $project.getState() }))
  .on(deleteFunnel, deleteId)

$funnelName
  .on(addFunnel, (dataMap, { id }) => ({ ...dataMap, [id]: `Воронка ${Object.keys(dataMap).length + 1}` }))
  .on(setName, (dataMap, { id, value }) => ({ ...dataMap, [id]: value }))
  .on(deleteFunnel, deleteId)

$funnelType
  .on(addFunnel, (dataMap, { id, funnelType }) => ({ ...dataMap, [id]: funnelType }))
  .on(deleteFunnel, deleteId)

$currentFunnelsType.on(setCurrentFunnelType, (_, type) => type)

function updateFieldInIdMap(map, id, field, value) {
  const prevValue = map[id] || {}
  return { ...map, [id]: { ...prevValue, [field]: value } }
}

function updateFieldsInIdMap(map, id, keyValue) {
  const prevValue = map[id] || {}
  return { ...map, [id]: { ...prevValue, ...keyValue } }
}

forward({ from: applyParams, to: fxLoadFunnelData })
forward({ from: fxLoadFunnelData.fail, to: getFunnelFailed })

$funnelMeta.watch(d => console.info('$funnelMeta', d))
