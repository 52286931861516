import React from 'react'
import { DefaultTemplate } from '../defaultTemplate'
import { EventsList } from 'features/analytics'

export function EventsPage() {
  return (
    <DefaultTemplate>
      <EventsList />
    </DefaultTemplate>
  )
}
