import React from 'react'
import moment from 'moment'
import styled from "styled-components"
import { useStore } from 'effector-react'
import { combine } from 'effector'
import { Table, Button, Tabs } from 'antd'

import { DefaultTemplate } from '../defaultTemplate'
import {
  updateDataClick,
  $cohorts,
  $periods,
  $leads,
  $firstCustomersTable,
  $c1Table,
  $c2Table,
  $allTransactionsTable,
  $totalMoneyTable,
  $avPriceTable,
  $isUnitDataFetching,
  $arpuTable
} from './model'
import './model/init'

const periodToString = period => {
  return moment(period.from).format('MMM YY')
}

function getColumns({ periods, cohorts }) {
  const periodColumns = periods.map((period, index) => ({
    title: periodToString(period),
    key: `period${index}`,
    dataIndex: index,
    render: (value, _, ind) => {
      if (index < ind) return ''
      if (index === ind) return <b>{value}</b>
      return value
    },
  }))

  return [
    {
      title: '',
      key: 'cohort',
      render: (_, __, index) => periodToString(cohorts[index]),
    },
    ...periodColumns,
  ]
}

const TabPaneSt = styled(Tabs.TabPane)`
      overflow: scroll;
`

function MetricTable({ metricTable, periods, cohorts, title }) {
  return (
    <>
      <h1>{title}</h1>
      <Table
        dataSource={metricTable}
        columns={getColumns({ periods, cohorts })}
        pagination={false}
        rowKey={(_, index) => index}
        bordered
      />
    </>
  )
}

function cropTable(table, showLast){
  return table.slice(-showLast).map(arr => arr.slice(-showLast))
}

const $stores = combine({
  cohorts: $cohorts,
  periods: $periods,
  leads: $leads,
  firstCustomersTable: $firstCustomersTable,
  c1Table: $c1Table,
  c2Table: $c2Table,
  allTransactionsTable: $allTransactionsTable,
  totalMoneyTable: $totalMoneyTable,
  arpuTable: $arpuTable,
  avPriceTable: $avPriceTable,
  isUnitDataFetching: $isUnitDataFetching,
})

export function UnitPage() {
  const {
    cohorts,
    periods,
    leads,
    firstCustomersTable,
    c1Table,
    c2Table,
    allTransactionsTable,
    totalMoneyTable,
    avPriceTable,
    isUnitDataFetching,
    arpuTable
  } = useStore($stores)

  const [showAll, setShowAll] = React.useState(false)

  const tabs = {
    leads: { title: 'Leads', store: leads },
    firstCustomersTable: { title: 'First Customers', store: firstCustomersTable },
    c1Table: { title: 'C1', store: c1Table },
    c2Table: { title: 'C2', store: c2Table },
    allTransactionsTable: { title: 'All Transactions', store: allTransactionsTable },
    avPriceTable: { title: 'Avg Price', store: avPriceTable },
    totalMoneyTable: { title: 'Total money', store: totalMoneyTable },
    arpuTable: { title: 'ARPU', store: arpuTable },
  }

  const showLast = showAll ? cohorts.length : 12;

  const croppedPeriods = periods.slice(-showLast)
  const croppedCohorts = cohorts.slice(-showLast)

  return (
    <DefaultTemplate>
      <Tabs defaultActiveKey="1" onChange={undefined}>
        {Object.entries(tabs).map(([tabName, tab]) => (
          <TabPaneSt tab={tab.title} key={tabName}>
            <MetricTable metricTable={cropTable(tab.store, showLast)} periods={croppedPeriods} cohorts={croppedCohorts} title={tab.title} />
          </TabPaneSt>
        ))}
      </Tabs>

      <br/>
      <Button onClick={() => updateDataClick()} disabled={isUnitDataFetching}>
        Обновить данные
      </Button>
      &nbsp;&nbsp;
      <Button onClick={() => setShowAll(!showAll)}>
        {showAll ? 'Показать последние 12 мес' : 'Показать все данные'}
      </Button>
    </DefaultTemplate>
  )
}
