import React, { useEffect } from 'react'
import { Routing } from './routing'
import { appMounted } from './model'

function App() {
  useEffect(() => {
    appMounted()
  }, [])
  return (
    <div>
      <Routing />
    </div>
  )
}

export default App
