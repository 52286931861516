import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Input, Popconfirm } from 'antd'
import { CohortsEdit } from './cohortsEdit'
import { EventsList } from 'features/analytics'

const Container = styled.div`
  > * {
    margin-bottom: 8px !important;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export function FunnelEdit({
  id,
  cohorts,
  setCohorts,
  pages,
  setPages,
  setName,
  applyParams,
  abOptions,
  utmLabels,
  name,
  onDeleteFunnel,
  funnelType,
}) {
  const [localName, setLocalName] = useState(name)
  const [localPages = [], setLocalPages] = useState(pages)

  const [localCohorts, setLocalCohorts] = useState(cohorts)
  function updateCohort({ index, newValues }) {
    const newCohorts = [...localCohorts]
    newCohorts[index] = { ...newCohorts[index], ...newValues }
    setLocalCohorts(newCohorts)
  }

  function deleteCohort({ index }) {
    const newCohorts = localCohorts.filter((_, ind) => ind !== index)
    setLocalCohorts(newCohorts)
  }
  function addCohort() {
    setLocalCohorts([...localCohorts, {}])
  }

  function apply() {
    setCohorts({ id, value: localCohorts })
    setPages({ id, value: localPages.filter(i => i.trim() !== '') })
    setName({ id, value: localName })
    applyParams({ id })
  }

  return (
    <Container>
      <Input value={localName} onChange={e => setLocalName(e.target.value)} />
      <CohortsEdit
        cohorts={localCohorts}
        updateCohort={updateCohort}
        deleteCohort={deleteCohort}
        addCohort={addCohort}
        abOptions={abOptions}
        utmLabels={utmLabels}
        funnelType={funnelType}
      />
      <Input.TextArea
        rows={6}
        onChange={v => setLocalPages(v.target.value.split('\n'))}
        value={localPages.join('\n')}
      />
      <ButtonsContainer>
        <Button onClick={apply} type="primary">
          Применить
        </Button>
        <Popconfirm
          title="Точно удалить воронку?"
          onConfirm={() => onDeleteFunnel({ id })}
          okText="Да"
          cancelText="Нет"
        >
          <a>Удалить</a>
        </Popconfirm>
      </ButtonsContainer>

      <h2>События</h2>
      <EventsList />
    </Container>
  )
}
