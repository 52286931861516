import { createStore } from 'effector'

export function createStoreWithLocalStorageSync(defaultValue, key, options) {
  if (!key) throw new Error('Provide key')
  const { serializer, deserializer } = options || {}

  const cacheKey = `stores.${key}`

  let res
  const storageValue = localStorage.getItem(cacheKey)
  let defaultValueForStore
  if (storageValue) {
    defaultValueForStore = deserializer ? deserializer(storageValue) : JSON.parse(storageValue)
  } else {
    defaultValueForStore = defaultValue
  }

  res = createStore(defaultValueForStore)

  res.updates.watch(val => {
    const serializedValue = serializer ? serializer(val) : JSON.stringify(val)
    localStorage.setItem(cacheKey, serializedValue)
  })
  return res
}
