import React from 'react'
import styled from 'styled-components'
import { Button, notification } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useStore } from 'effector-react'
import { DefaultTemplate } from '../defaultTemplate'
import {
  addFunnelClick,
  $funnels,
  applyParams,
  startEdit,
  getFunnelFailed,
  setCohorts,
  setPages,
  setName,
  $abOptions,
  $utmLabels,
  deleteFunnel,
  setCurrentFunnelType,
} from '../../features/analytics'
import { FunnelView, FunnelEdit, UsersListModal } from './components'

const Buttons = styled.div`
  margin-top: 16px;
`

const Item = styled.div`
  border-bottom: 2px solid #a2a2a2;

  margin-bottom: 40px;
  padding-bottom: 32px;
`

getFunnelFailed.watch(({ error }) => {
  const message =
    error.response && error.response.data && error.response.data.error && error.response.data.error.message
  if (message) {
    notification.error({ message: 'Не удалось получить данные для воронки =(', description: message })
  }
})

function CreateFunnelButton() {
  return (
    <Buttons>
      <Button onClick={addFunnelClick}>
        <PlusCircleOutlined /> Создать воронку
      </Button>
    </Buttons>
  )
}

function FunnelsPage({ funnelType }) {
  React.useEffect(() => {
    setCurrentFunnelType(funnelType)
  }, [])

  const funnels = useStore($funnels)
  const abOptions = useStore($abOptions)
  const utmLabels = useStore($utmLabels)

  return (
    <DefaultTemplate>
      <CreateFunnelButton />
      <br />
      <br />
      <br />
      {funnels.reverse().map(({ id, pages, cohorts, mode, data, meta, name }) => (
        <Item key={id}>
          {mode === 'edit' ? (
            <FunnelEdit
              pages={pages}
              setPages={setPages}
              setName={setName}
              cohorts={cohorts}
              setCohorts={setCohorts}
              mode={mode}
              applyParams={applyParams}
              id={id}
              abOptions={abOptions}
              utmLabels={utmLabels}
              name={name}
              onDeleteFunnel={deleteFunnel}
              funnelType={funnelType}
            />
          ) : (
            <FunnelView
              id={id}
              startEdit={startEdit}
              funnelData={data}
              meta={meta}
              abOptions={abOptions}
              utmLabels={utmLabels}
              name={name}
              applyParams={applyParams}
              funnelType={funnelType}
            />
          )}
        </Item>
      ))}
      <CreateFunnelButton />

      <UsersListModal />
    </DefaultTemplate>
  )
}

export function SimpleFunnelsPage({ props }) {
  return <FunnelsPage {...props} funnelType="simple" />
}

export function MultiFunnelsPage({ props }) {
  return <FunnelsPage {...props} funnelType="multi" />
}
