import { createEvent, createStore, createEffect, forward } from 'effector'
import { getABOptions } from 'api/gaApi'

// events
export const loadAbOptions = createEvent()

// effects
export const fxLoadAbOptions = createEffect().use(loadAbOptionsHandler)

// stores
export const $abOptions = createStore([])

//
// business logic
//
function loadAbOptionsHandler(){
  return getABOptions().then(r => r.abOptions)
}

$abOptions.on(fxLoadAbOptions.done, (_, {result}) => result)

forward({ from: loadAbOptions, to: fxLoadAbOptions })

