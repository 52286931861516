import { createEffect, createEvent, createStore } from 'effector'
import { getExpireClients } from 'api/gaApi'

export const loadExpireClientsFx = createEffect().use(() => getExpireClients())

export const $expireClients = createStore([])

$expireClients.on(loadExpireClientsFx.doneData, (_, resp) => resp)


$expireClients.watch(d => console.info('$expireClients', d))
